'use client';

import { createContext, useContext, useState } from 'react';

import { OpenWithTypes } from '@/components/forms/login-signup-form';
import { useGlobalState } from '@/components/global/global-state';
import { LoginSignupModal } from '@/components/modals/login-signup-modal';
import { Nullable } from '@/types/nullable';

export enum SignUpSource {
    DEFAULT = 'default',
    EVAL_LIMIT_REACHED = 'eval_limit_reacted',
    RESEARCH_PROMPT = 'research_prompt',
}

export const SignUpSourceCopyMap: {
    [key in SignUpSource]: React.ReactNode;
} = {
    [SignUpSource.DEFAULT]: null,
    [SignUpSource.EVAL_LIMIT_REACHED]: (
        <>
            You&apos;ve reached the maximum number of company evaluations. To get access to more evaluations, sign up
            for a <strong className="tracking-wide text-thematic-purple">free</strong> Thematic account.
        </>
    ),
    [SignUpSource.RESEARCH_PROMPT]: (
        <>
            To access our research platform, sign up for a{' '}
            <strong className="tracking-wide text-thematic-purple">free</strong> Thematic account.
        </>
    ),
};

interface ILoggedInGateContext {
    closeLoginModal: () => void;
    isLoggedIn: boolean;
    modalType: OpenWithTypes;
    openLoginModal: (
        evt: Nullable<React.MouseEvent<Element, MouseEvent>>,
        signUpSource?: SignUpSource,
        modalType?: OpenWithTypes,
        props?: Record<string, unknown>
    ) => void;
    showLoginModal: boolean;
}

interface LoggedInGateProviderProps {
    children: React.ReactNode;
}

export const LoggedInGateContext = createContext<Nullable<ILoggedInGateContext>>(null);

export const LoggedInGateProvider = ({ children }: LoggedInGateProviderProps) => {
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;
    const isLoggedIn = !!currentUser && !currentUserLoading;
    const [showLoginModal, setShowLoginModalState] = useState<boolean>(false);
    const [modalType, setModalType] = useState<OpenWithTypes>(OpenWithTypes.SIGNUP);
    const [signUpSource, setSignUpSource] = useState<SignUpSource>(SignUpSource.DEFAULT);
    const [modalProps, setModalProps] = useState({});
    const openLoginModal = (
        evt: Nullable<React.MouseEvent<Element, MouseEvent>>,
        signUpSource: SignUpSource = SignUpSource.DEFAULT,
        modalType: OpenWithTypes = OpenWithTypes.LOGIN,
        props = {}
    ) => {
        evt?.preventDefault();
        setModalType(modalType);
        setShowLoginModalState(true);
        setSignUpSource(signUpSource);
        setModalProps(props);
    };
    const closeLoginModal = () => setShowLoginModalState(false);
    const values: ILoggedInGateContext = {
        closeLoginModal,
        isLoggedIn,
        modalType,
        openLoginModal,
        showLoginModal,
    };

    return (
        <LoggedInGateContext.Provider value={values}>
            {children}
            <LoginSignupModal
                isOpen={showLoginModal}
                openWith={modalType}
                closeFn={closeLoginModal}
                signUpSource={signUpSource}
                {...modalProps}
            />
        </LoggedInGateContext.Provider>
    );
};

export const useLoggedInGate = () => {
    const context: Nullable<ILoggedInGateContext> = useContext(LoggedInGateContext);

    if (!context) {
        throw new Error('useLoggedInGate must be used within a LoggedInGateProvider');
    }

    return context;
};
