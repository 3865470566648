import { Heading } from '@/components/dom/text-elements';
import { GetFundamentalColumn } from '@/helpers/fundamentals';
import { AnalystFilter, AnalystFilterPredicate } from '@/queries/analyst';

enum FundamentalField {
    MARKET_CAPITALIZATION_USD = 'MARKET_CAPITALIZATION_USD',
    PRICE_EARNINGS_RATIO = 'PRICE_EARNINGS_RATIO',
    EBITDA_MARGIN = 'EBITDA_MARGIN',
    ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE = 'ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE',
    GROSS_INCOME_MARGIN = 'GROSS_INCOME_MARGIN',
    DIVIDEND_YIELD_DAILY_PERCENT = 'DIVIDEND_YIELD_DAILY_PERCENT',
    DIVIDEND_PER_SHARE = 'DIVIDEND_PER_SHARE',
    EARNINGS_PER_SHARE = 'EARNINGS_PER_SHARE',
    ENTERPRISE_VALUE_REVENUE_RATIO = 'ENTERPRISE_VALUE_REVENUE_RATIO',
    PRICE_TO_BOOK_RATIO = 'PRICE_TO_BOOK_RATIO',
    PRICE_TO_SALES_RATIO = 'PRICE_TO_SALES_RATIO',
    RETURN_ON_TOTAL_CAPITAL = 'RETURN_ON_TOTAL_CAPITAL',
    RETURN_ON_ASSETS = 'RETURN_ON_ASSETS',
    RETURN_ON_TOTAL_EQUITY = 'RETURN_ON_TOTAL_EQUITY',
    EBITDA = 'EBITDA',
    NET_INCOME = 'NET_INCOME',
    NET_INCOME_MARGIN = 'NET_INCOME_MARGIN',
    PRICE_THREE_MONTH_SHARPE_RATIO = 'PRICE_THREE_MONTH_SHARPE_RATIO',
    PRICE_THREE_MONTH_STANDARD_DEVIATION = 'PRICE_THREE_MONTH_STANDARD_DEVIATION',
    PRICE_THREE_MONTH_ROLLING_VOLATILITY = 'PRICE_THREE_MONTH_ROLLING_VOLATILITY',
    PRICE_THREE_MONTH_DRAWDOWN = 'PRICE_THREE_MONTH_DRAWDOWN',
    ONE_YEAR_PRICE_PERFORMANCE = 'ONE_YEAR_PRICE_PERFORMANCE',
    THREE_YEAR_PRICE_PERFORMANCE = 'THREE_YEAR_PRICE_PERFORMANCE',
}

type FundamentalFieldDetailsType = {
    fundamentalColumnKey: string;
    type: 'PERCENTAGE' | 'BASIC' | 'BASIC_CURRENCY' | 'SCALED_CURRENCY';
};

export const FundamentalFieldDetails: {
    [key in FundamentalField]: FundamentalFieldDetailsType;
} = {
    [FundamentalField.MARKET_CAPITALIZATION_USD]: {
        fundamentalColumnKey: 'market_capitalization_usd',
        type: 'SCALED_CURRENCY',
    },
    [FundamentalField.EBITDA]: {
        fundamentalColumnKey: 'ebitda',
        type: 'SCALED_CURRENCY',
    },
    [FundamentalField.NET_INCOME]: {
        fundamentalColumnKey: 'net_income',
        type: 'SCALED_CURRENCY',
    },
    [FundamentalField.EARNINGS_PER_SHARE]: {
        fundamentalColumnKey: 'earnings_per_share',
        type: 'BASIC_CURRENCY',
    },
    [FundamentalField.ONE_YEAR_ANNUAL_REVENUE_GROWTH_RATE]: {
        fundamentalColumnKey: 'one_year_annual_revenue_growth_rate',
        type: 'PERCENTAGE',
    },
    [FundamentalField.NET_INCOME_MARGIN]: {
        fundamentalColumnKey: 'net_income_margin',
        type: 'PERCENTAGE',
    },
    [FundamentalField.EBITDA_MARGIN]: {
        fundamentalColumnKey: 'ebitda_margin',
        type: 'PERCENTAGE',
    },
    [FundamentalField.GROSS_INCOME_MARGIN]: {
        fundamentalColumnKey: 'gross_income_margin',
        type: 'PERCENTAGE',
    },
    [FundamentalField.PRICE_EARNINGS_RATIO]: {
        fundamentalColumnKey: 'price_earnings_ratio',
        type: 'PERCENTAGE',
    },
    [FundamentalField.PRICE_TO_BOOK_RATIO]: {
        fundamentalColumnKey: 'price_to_book_ratio',
        type: 'PERCENTAGE',
    },
    [FundamentalField.PRICE_TO_SALES_RATIO]: {
        fundamentalColumnKey: 'price_to_sales_ratio',
        type: 'PERCENTAGE',
    },
    [FundamentalField.ENTERPRISE_VALUE_REVENUE_RATIO]: {
        fundamentalColumnKey: 'enterprise_value_revenue_ratio',
        type: 'BASIC',
    },
    [FundamentalField.RETURN_ON_TOTAL_CAPITAL]: {
        fundamentalColumnKey: 'return_on_total_capital',
        type: 'PERCENTAGE',
    },
    [FundamentalField.RETURN_ON_ASSETS]: {
        fundamentalColumnKey: 'return_on_assets',
        type: 'PERCENTAGE',
    },
    [FundamentalField.RETURN_ON_TOTAL_EQUITY]: {
        fundamentalColumnKey: 'return_on_total_equity',
        type: 'PERCENTAGE',
    },
    [FundamentalField.DIVIDEND_YIELD_DAILY_PERCENT]: {
        fundamentalColumnKey: 'dividend_yield_daily_percent',
        type: 'PERCENTAGE',
    },
    [FundamentalField.DIVIDEND_PER_SHARE]: {
        fundamentalColumnKey: 'dividend_per_share',
        type: 'BASIC_CURRENCY',
    },
    [FundamentalField.ONE_YEAR_PRICE_PERFORMANCE]: {
        fundamentalColumnKey: 'one_year_price_performance',
        type: 'PERCENTAGE',
    },
    [FundamentalField.THREE_YEAR_PRICE_PERFORMANCE]: {
        fundamentalColumnKey: 'three_year_price_performance',
        type: 'PERCENTAGE',
    },
    [FundamentalField.PRICE_THREE_MONTH_SHARPE_RATIO]: {
        fundamentalColumnKey: 'price_three_month_sharpe_ratio',
        type: 'BASIC',
    },
    [FundamentalField.PRICE_THREE_MONTH_STANDARD_DEVIATION]: {
        fundamentalColumnKey: 'price_three_month_standard_deviation',
        type: 'BASIC',
    },
    [FundamentalField.PRICE_THREE_MONTH_ROLLING_VOLATILITY]: {
        fundamentalColumnKey: 'price_three_month_rolling_volatility',
        type: 'BASIC',
    },
    [FundamentalField.PRICE_THREE_MONTH_DRAWDOWN]: {
        fundamentalColumnKey: 'price_three_month_drawdown',
        type: 'BASIC',
    },
};

type InFilterType = {
    field: FundamentalField;
    comparator: 'IN';
    values: Array<string>;
    asString?: never;
};

type ComparisonFilterType = {
    field: FundamentalField;
    comparator: 'GT' | 'GTE' | 'LT' | 'LTE';
    value: number;
    asString?: never;
};

type UnhandledFilter = {
    field?: never;
    comparator?: never;
    asString: string;
};

type HandledFilter = InFilterType | ComparisonFilterType;

// TODO: move this type to its own file, rather than in this component file, since it's reused across components
export type CoreFilterType = HandledFilter | UnhandledFilter;
export type CoreFiltersType = Array<HandledFilter | UnhandledFilter>;

export const coreFiltersFromAnalystFilter = (analystFilter: AnalystFilter | null): CoreFiltersType => {
    if (!analystFilter) return [];

    const parsePredicate = (predicate: AnalystFilterPredicate): CoreFiltersType => {
        if (predicate.__typename === 'InstrumentSearchComparisonPredicate') {
            return [
                {
                    comparator: predicate.comparator,
                    field: predicate.field as FundamentalField,
                    value: Number(predicate.value),
                },
            ];
        }
        if (predicate.__typename === 'InstrumentSearchInPredicate') {
            return [{ comparator: 'IN', field: predicate.field as FundamentalField, values: predicate.values }];
        }
        if (predicate.__typename === 'InstrumentSearchAndPredicate') {
            return predicate.predicates.flatMap(parsePredicate);
        }

        if (predicate.asString) {
            return [{ asString: predicate.asString }];
        }

        throw new Error(`Unrecognized predicate ${JSON.stringify(predicate)}`);
    };

    return parsePredicate(analystFilter);
};

export const coreFiltersUniqueFieldCount = (coreFilters: CoreFiltersType) => {
    const fields = coreFilters.filter(({ field }) => !!field).map(({ field }) => field);
    const uniqueFields = new Set(fields).size;

    const unhandled = coreFilters.filter(({ field }) => !field).length;

    return uniqueFields + unhandled;
};

export const CoreFilters = ({ coreFilters }: { coreFilters: CoreFiltersType }) => {
    const comparatorString = (comparator: string) => {
        switch (comparator) {
            case 'GT':
                return 'min';
            case 'GTE':
                return 'min';
            case 'LT':
                return 'max';
            case 'LTE':
                return 'max';
            default:
                return comparator;
        }
    };

    return (
        !!coreFilters.length && (
            <section className="w-full max-w-[800px] mx-auto mb-8">
                <Heading
                    importance={5}
                    className="mb-4"
                >
                    Core Filters
                </Heading>

                <div className="flex flex-wrap">
                    {coreFilters
                        .map((coreFilter) => {
                            if (coreFilter.comparator && coreFilter.comparator === 'IN') {
                                const fieldDetails = FundamentalFieldDetails[coreFilter.field];
                                const fundamentalMapping = GetFundamentalColumn(
                                    fieldDetails?.fundamentalColumnKey || coreFilter.field.toLowerCase()
                                );
                                const field = fundamentalMapping?.displayLabel || coreFilter.field.toLowerCase();
                                if (coreFilter.values.length === 1) {
                                    return `${field}: ${coreFilter.values[0]}`;
                                } else {
                                    return `${field}: ${coreFilter.values.join(', ')}`;
                                }
                            } else if (
                                coreFilter.comparator &&
                                ['LT', 'LTE', 'GT', 'GTE'].includes(coreFilter.comparator)
                            ) {
                                const fieldDetails = FundamentalFieldDetails[coreFilter.field];
                                const fundamentalMapping = GetFundamentalColumn(
                                    fieldDetails?.fundamentalColumnKey || coreFilter.field.toLowerCase()
                                );
                                const field = fundamentalMapping?.displayLabel || coreFilter.field.toLowerCase();
                                const value = (fundamentalMapping?.formatFunc || ((x: string) => x))(coreFilter.value);
                                return `${field}: ${value} ${comparatorString(coreFilter.comparator)}`;
                            } else {
                                return coreFilter.asString || '';
                            }
                        })
                        .map((text, index) => {
                            return (
                                <div
                                    key={`filter-${index}`}
                                    className="m-1 py-2 px-4 bg-analyst-light-gray brand-gray-light rounded-full whitespace-nowrap font-brand-sm"
                                >
                                    {text}
                                </div>
                            );
                        })}
                </div>
            </section>
        )
    );
};

export const coreFiltersToAnalystFilterInput = (coreFilters: CoreFiltersType) => {
    if (coreFilters.length === 0) return null;

    // Don't pass unhandled filters

    // Group filters by each field
    const filtersByField = (coreFilters.filter((coreFilter) => !!coreFilter.comparator) as Array<HandledFilter>).reduce(
        (acc: { [key in FundamentalField]?: Array<HandledFilter> }, coreFilter) => {
            const fieldFilters = acc[coreFilter.field] || [];
            return { ...acc, [coreFilter.field]: fieldFilters.concat([coreFilter]) };
        },
        {}
    );

    const filters = Object.values(filtersByField)
        .flatMap((fieldFilters) => {
            // Group filters for each field by comparator
            const filtersByComparator = fieldFilters.reduce(
                (acc: { [key: string]: Array<HandledFilter> }, coreFilter) => {
                    const comparatorFilters = acc[coreFilter.comparator] || [];
                    return { ...acc, [coreFilter.comparator]: comparatorFilters.concat([coreFilter]) };
                },
                {}
            );

            // Dedup comparators for which we can dedup
            return Object.entries(filtersByComparator).flatMap(([comparator, comparatorFilters]) => {
                if (['LT', 'LTE'].includes(comparator)) {
                    // Keep only the *lowest* for `LT`/`LTE`
                    return (comparatorFilters as Array<ComparisonFilterType>).reduce((a, b) =>
                        a.value < b.value ? a : b
                    );
                } else if (['GT', 'GTE'].includes(comparator)) {
                    // Keep only the *highest* for `LT`/`LTE`
                    return (comparatorFilters as Array<ComparisonFilterType>).reduce((a, b) =>
                        a.value > b.value ? a : b
                    );
                } else {
                    // TODO: dedup `IN` by taking the intersection?
                    return comparatorFilters;
                }
            }, []);
        })
        .map((coreFilter) => {
            // Convert filters to GQL input format
            if (coreFilter.comparator === 'IN') {
                return { in: { field: coreFilter.field, values: coreFilter.values } };
            } else {
                return {
                    comparison: {
                        comparator: coreFilter.comparator,
                        field: coreFilter.field,
                        value: coreFilter.value,
                    },
                };
            }
        });

    return { and: { filters } };
};
