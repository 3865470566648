import { LoginSignupForm, OpenWithTypes } from '@/components/forms/login-signup-form';
import BaseModal from '@/components/global/base-modal';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { CurrentUserType } from '@/queries/current-user';
import { type BaseTrackingProperties, ModalTypes } from '@/types/tracking';

import { SignUpSource } from '../providers/LoggedInGate';

type LoginSignupModalProps = {
    isOpen: boolean;
    closeFn: () => void;
    signUpCopy?: React.ReactNode;
    signUpSource?: SignUpSource;
    logInCopy?: React.ReactNode;
    openWith?: OpenWithTypes;
    onLoginSuccess?: (user: CurrentUserType) => void;
    trackingProperties?: Omit<BaseTrackingProperties, 'component'>;
};

export const LoginSignupModal = ({
    closeFn,
    isOpen,
    signUpCopy,
    signUpSource,
    logInCopy,
    onLoginSuccess,
    openWith = OpenWithTypes.SIGNUP,
    trackingProperties = {} as BaseTrackingProperties,
}: LoginSignupModalProps) => {
    const { categories, components } = usePosthogTracking();

    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={closeFn}
            headerClassName="text-center text-3xl"
            className="rounded-lg border border-analyst-dark-lavender shadow-lg pt-4 bg-gradient-to-b to-analyst-lavender from-10% from-analyst-white max-w-xl z-1000"
            portalClassName="z-9999"
            size="1/2"
            trackingProperties={{
                ...trackingProperties,
                category: categories.LOGIN_SIGNUP,
                component: components.LOGIN_SIGNUP_MODAL,
                modalType: ModalTypes.LOGIN_SIGNUP,
            }}
        >
            <div className="px-8 pb-8">
                <LoginSignupForm
                    {...{
                        logInCopy,
                        onSuccess: onLoginSuccess,
                        openWith,
                        signUpCopy,
                        signUpSource,
                        trackingProperties,
                    }}
                />
            </div>
        </BaseModal>
    );
};
