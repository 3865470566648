'use client';

import posthog, { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

import { FeatureFlags } from '@/lib/constants/tracking';

interface ThematicPostHogProviderProps {
    children: React.ReactNode;
    options?: PostHogConfig;
}

const usePosthogInit = () => {
    useEffect(() => {
        if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_API_KEY) {
            // checks that we are client-side
            posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
                api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
                autocapture: true,
                bootstrap: {
                    [FeatureFlags.NEW_SECTIONED_COMPANY_EVALUATION_PAGE]: true,
                },
                loaded: (posthog) => {
                    if (process.env.NODE_ENV === 'development') {
                        posthog.debug(); // debug mode in development
                    }
                },
                person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
                // https://posthog.com/docs/session-replay/privacy
                session_recording: {
                    maskAllInputs: false,
                    maskInputOptions: {
                        password: true,
                    },
                },
            });
        }
    }, []);
};

export const ThematicPostHogProvider = ({ children, options }: ThematicPostHogProviderProps) => {
    usePosthogInit();
    return (
        <PostHogProvider
            client={posthog}
            options={options}
        >
            {children}
        </PostHogProvider>
    );
};
