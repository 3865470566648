import { gql } from 'graphql-request';
import { usePathname, useRouter } from 'next/navigation';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { FieldError, Label, SubmitButton, TextInput } from '@/components/dom/form-elements';
import { useGlobalState } from '@/components/global/global-state';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { PRODUCT_PATHNAMES } from '@/lib/constants/general';
import { GQL_CLIENT } from '@/lib/graphql';
import { CURRENT_USER_FIELDS, CurrentUserType } from '@/queries/current-user';
import { type BaseTrackingProperties, type ErrorTrackingProperties } from '@/types/tracking';

const OTP_QUERY = gql`
  mutation CreateUserSession($loginAttemptId: String!, $challengeToken: String!) {
      createUserSession(input: { loginAttemptId: $loginAttemptId, challengeToken: $challengeToken }) {
          __typename
          ... on UserSession {
              user {
                  ${CURRENT_USER_FIELDS}
              }
              sessionToken
              sessionExpiresAt
          }
          ... on Error {
              message
          }
      }
  }
`;

interface OTPFormProps {
    loginAttemptId: string;
    onLoginSuccess?: (user: CurrentUserType) => void;
    submitButtonCopy: string;
    trackingProperties: Omit<BaseTrackingProperties, 'component'>;
}

export const OTPForm = ({
    loginAttemptId,
    onLoginSuccess,
    submitButtonCopy,
    trackingProperties = {} as BaseTrackingProperties,
}: OTPFormProps) => {
    const { push } = useRouter();
    const pathname = usePathname();
    const { categories, components, eventTypes, trackManualEvent } = usePosthogTracking();
    const { setGlobalState } = useGlobalState();
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const onOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtp(event.target.value);
        setOtpError('');
    };
    const submitOtpForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const variables = {
            challengeToken: otp,
            loginAttemptId,
        };

        trackManualEvent({
            eventType: eventTypes.OTP_FORM_SUBMIT,
            trackingProperties: {
                ...trackingProperties,
                additionalTrackingProperties: {
                    loginAttemptId,
                },
                category: categories.LOGIN_SIGNUP,
                component: components.OTP_FORM,
            },
        });

        const data: {
            createUserSession:
                | {
                      __typename: 'UserSession';
                      user: CurrentUserType;
                      sessionToken: string;
                      sessionExpiresAt: string;
                  }
                | {
                      __typename: 'Error';
                      message: string;
                  };
        } = await GQL_CLIENT.request(OTP_QUERY, variables);

        const result = data.createUserSession;
        const success = result.__typename === 'UserSession';

        if (success) {
            const isOnProductPage = PRODUCT_PATHNAMES.some((productPathname) => pathname?.startsWith(productPathname));

            setGlobalState((prev) => {
                return {
                    ...prev,
                    currentUser: result.user,
                };
            });
            trackManualEvent({
                eventType: eventTypes.OTP,
                trackingProperties: {
                    ...trackingProperties,
                    category: categories.LOGIN_SIGNUP,
                    component: components.OTP_FORM,
                    email: result.user.email,
                    name: result.user.name,
                    userId: result.user.id,
                },
            });

            toast.success('You’re now signed in.');

            onLoginSuccess?.(result.user);

            // Redirect to /app/start if user is not on a product page
            if (!isOnProductPage) {
                push('/app/start');
            }
        } else {
            setOtpError(result.message);

            trackManualEvent({
                eventType: eventTypes.OTP_ERROR,
                trackingProperties: {
                    ...trackingProperties,
                    category: categories.LOGIN_SIGNUP,
                    component: components.OTP_FORM,
                    errorType: 'otp',
                    message: result.message,
                } as ErrorTrackingProperties,
            });
        }
        setIsSubmitting(false);
    };

    return (
        <form onSubmit={submitOtpForm}>
            <div className="mb-6">
                <Label
                    text="One-time passcode"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-md mb-2 text-base"
                >
                    <TextInput
                        fieldClassName="w-full rounded-full px-3 py-2"
                        value={otp}
                        onChange={onOTPChange}
                        required={true}
                        spellCheck={false}
                        placeholder="ex. 123456"
                    />
                </Label>
                <FieldError message={otpError} />
            </div>
            <SubmitButton
                isDisabled={isSubmitting}
                value={submitButtonCopy}
                roundedCorners="full"
                className="font-brand-md mx-0 w-full"
                color="primary-gradient"
            />
        </form>
    );
};
